import api from "./index";

export const payoutApi = api.injectEndpoints({
    endpoints: (builder) => ({
        findPayouts: builder.query<Payment[], WithPagination<PaymentFormFind>>({
            query: (form) => {
                const params: Record<string, string> = {};
                if (form.data) {
                    let k: keyof PaymentFormFind;
                    for (k in form.data) {
                        if (k && form.data[k]) {
                            if (Array.isArray(form.data[k])) {
                                const x: number[] = form.data[k] as number[];
                                for (let idx in x) {
                                    params[`f[${k}][${idx}]`] = x[idx].toString();
                                }
                            } else {
                                params[`f[${k}]`] = form.data[k]!.toString();
                            }
                        }
                    }
                }
                return { url: `hall/payment?${new URLSearchParams(params).toString()}`, method: "get" };
            },
            transformResponse: (response: any) => response.data,
            providesTags: (_result, _error, args) => [{ type: "payments", id: args.data!.hall_id }],
        }),
        confirmPayout: builder.mutation<DefaultResponse<boolean>, BankCardRequestId>({
            query: (request) => ({ url: `hall/payment/confirm/${request.id}`, method: "post" }),
            invalidatesTags: (_result, _error, args) => [{ type: "payments", id: args.hall_id }],
        }),
        cancelPayout: builder.mutation<DefaultResponse<boolean>, BankCardRequestId>({
            query: request => ({ url: `hall/payment/cancel/${request.id}`, method: "post" }),
            invalidatesTags: (_result, _error, args) => [{ type: "payments", id: args.hall_id }],
        }),
        processPayout: builder.mutation<DefaultResponse<boolean>, BankCardRequestId>({
            query: request => ({ url: `hall/payment/process/${request.id}`, method: "post" }),
            invalidatesTags: (_result, _error, args) => [{ type: "payments", id: args.hall_id }],
        }),
    }),
});

export const { useFindPayoutsQuery, useCancelPayoutMutation, useConfirmPayoutMutation, useProcessPayoutMutation } = payoutApi;