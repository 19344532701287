import { Box, Chip, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { prettyNum } from "../../../utils/prettyNum";
import { IHall } from "../../../interfaces";

const useStyles = makeStyles(() => ({
    chip: {
        margin: '.5vh',
        padding: '0 1vh'
    },
}));

interface LimitsProps {
    hall: IHall;
};

const Limits = ({ hall }: LimitsProps) => {
    const [t] = useTranslation();
    const classes = useStyles();
    return <>
        <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.halls.fields.max_win_from_deposit')}:</b>
                <Chip
                    className={classes.chip}
                    label={<span>{Number(hall?.max_win_from_deposit)}</span>}
                />
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.halls.fields.max_win_per_spin')}:</b>
                <Chip
                    className={classes.chip}
                    label={<span>{prettyNum(hall?.max_win_per_spin)}</span>}
                />
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.halls.fields.win_per_spin_percent_bet')}:</b>
                <Chip
                    className={classes.chip}
                    label={<span>{Number(hall?.win_per_spin_percent_bet)}</span>}
                />
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.halls.fields.win_from_deposit_for_lose')}:</b>
                <Chip
                    className={classes.chip}
                    label={<span>{Number(hall?.win_from_deposit_for_lose)}</span>}
                />
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.halls.fields.max_deposit_for_lose')}:</b>
                <Chip
                    className={classes.chip}
                    label={<span>{prettyNum(hall?.max_deposit_for_lose)}</span>}
                />
            </Box>
        </Grid>
    </>;
}

export default Limits;