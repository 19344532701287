import React from 'react'
import HallForm from '../Form'
import { Wrapper } from './styles'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, IconButton } from '@material-ui/core'
import ErrorBoundary from '../../../utils/errorBoundary'
import { HallData, IHallMathForm } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface AddHallProps {
  user_id?: number
  hideAddHall: () => void
  handlerAddHall: (
    data: HallData,
    packagesData: any,
    math: IHallMathForm,
    hideForm: (hall_id?: number) => void,
    id: number | undefined
  ) => void
}

const AddHall: React.FC<AddHallProps> = ({ user_id, hideAddHall, handlerAddHall }) => {
  const classes = useStyles()
  const history = useHistory()
  const [t] = useTranslation()

  const [tab, setTab] = React.useState(0)

  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideAddHall}>
        <CloseIcon />
      </IconButton>
      <h3>{t('ru.halls.add_form')}</h3>
      <ErrorBoundary>
        <HallForm
          user_id={user_id}
          onSubmit={handlerAddHall}
          cancelBtnEvent={hideAddHall}
          cancelBtnText={t('ru.common.cancel_btn')}
          submitBtnText={t('ru.common.add_btn')}
          hideForm={(hall_id?: number) => {
            hideAddHall()
            history.push(`halls?hall_id=${hall_id}`)
          } }
          tab={tab}
          setTab={setTab} oferta={0} cards={0} />
      </ErrorBoundary>
    </Wrapper>
  )
}

export default AddHall
