import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Preloader from "../../Common/Preloader";
import Overlay from "../../Common/Overlay/index";
import AddCard from "../Add";
import { useTranslation } from "react-i18next";
import { useDeleteCardMutation, useFindCardsQuery } from "../../../api/card";
import Confirm from "../../Common/Confirm";
import CardTableRow from "./card-table-row";
import ChangeCard from "../Change";
import ViewCard from "../View";
import StyledTableCell from "./styled-table-sell";
import { useStyles } from "./styles";

interface CardsListProps {
  hall_id: number;
  change: boolean;
}
const CardsList: React.FC<CardsListProps> = ({ hall_id, change }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [isAddCard, setIsAddCard] = useState(false);
  const [editCard, setEditCard] = useState<BankCardRequestUpdate | null>(null);
  const [deleteCardId, setDeleteCardId] = useState(0);
  const [viewCardId, setViewCardId] = useState(0);

  const form: BankCardFormFind = { hall_id, status: 1 };

  const { data: cards, isLoading } = useFindCardsQuery({ data: form });
  const [deleteCard, { isLoading: isCardDeleting }] = useDeleteCardMutation();
  return <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ width: "100%" }}>
    {!cards || isLoading ? (
      <Preloader />
    ) : (
      <>
        <TableContainer component={Paper} className={classes.container}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{t("ru.card.fields.id")}</StyledTableCell>
                <StyledTableCell align="center">{t("ru.card.fields.name")}</StyledTableCell>
                <StyledTableCell align="center">{t("ru.card.fields.number")}</StyledTableCell>
                <StyledTableCell align="center">{t("ru.card.fields.bank")}</StyledTableCell>
                <StyledTableCell align="center">{""}</StyledTableCell>
                <StyledTableCell align="right">
                  {change && (
                    <Button variant="contained" onClick={() => setIsAddCard(true)}>
                      {t("ru.common.add_btn")}
                    </Button>
                  )}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.list}>
              {cards.map(card => <CardTableRow key={card.id} card={card} change={change} setViewCardId={setViewCardId} setDeleteCardId={setDeleteCardId} setEditCard={setEditCard} />)}
            </TableBody>
          </Table>
        </TableContainer>
        {cards.length === 0 && <Box style={{ width: "100%", padding: "25px 0" }} display="flex" justifyContent="center">
          <Typography>{t("ru.common.is_absent")}</Typography>
        </Box>}
      </>
    )}
    {!!viewCardId && <Overlay>
      <ViewCard id={viewCardId} hide={() => setViewCardId(0)} hall_id={hall_id} />
    </Overlay>}
    {isAddCard && <Overlay>
      <AddCard
        hall_id={hall_id}
        hide={() => setIsAddCard(false)}
      />
    </Overlay>}
    {!!editCard && <Overlay>
      <ChangeCard
        hide={() => setEditCard(null)}
        card={editCard}
      />
    </Overlay>}
    {!!deleteCardId && <Overlay>
      <Confirm
        text={`${t("ru.common.confirm.delete")} карту №${deleteCardId}?`}
        loading={isCardDeleting}
        cancel={() => setDeleteCardId(0)}
        submit={() => deleteCard({ id: deleteCardId, hall_id }).then(resp => "data" in resp && setDeleteCardId(0))}
      />
    </Overlay>}
  </Box>;
}

export default CardsList
