import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  authSelector,
  handlerTakeAdminBalance,
  handlerPutAdminBalance,
  handleLogout,
  handleOpenCashbox,
  handleCloseCashbox,
  cashboxSucces,
  clearState,
} from '../../redux/authReducer'

import { Wrapper, Logo, Balance, User, UserBlock, CashboxAlert } from './styles'
import { Burger } from '../Common/MenuIcons'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import CancelIcon from '@material-ui/icons/Cancel'
import cashbox from '../../assets/images/cashbox.png'
// import Notification from '../../assets/images/push.png'
import {
  Button,
  IconButton,
  Box,
  MenuItem,
  Menu,
  Tooltip,
  FormLabel,
  CircularProgress,
  makeStyles,
  Select,
  Grid,
  Theme,
} from '@material-ui/core'
import { withRouter, Link, RouteComponentProps } from 'react-router-dom'

import { prettyNum } from '../../utils/prettyNum'
import PaymentForm from '../Payment'
import Overlay from '../Common/Overlay/index'
import { useShowBlock } from '../../hooks/useShowBlock'
import Confirm from '../Common/Confirm'
import { UserBalance } from '../../interfaces'
import {
  getHallsList,
  hallChangeBalance,
  hallDataChangeBalance,
  hallSelector,
} from '../../redux/hallReducer'
import { findPermission } from '../../utils/find'
import { alertBlock } from '../../services/Auth'
import { useTranslation } from 'react-i18next'
import { convertUTCDateToLocalDate } from '../../utils/date'
import { currencySelector, getCurrencyList } from '../../redux/currencyReducer'
import { treeInfoChangeCurrentGame, treeInfoSelector } from '../../redux/treeInfoReducer'
import {
  changeTableCurrentGame,
  tableChangeBalance,
  tableChangeIsOut,
  tableDataChangeBalance,
  tableSelector,
  tablesListChangeCurrentGame,
  tablesListChangeHighscore,
  tablesListChangeOnline,
  tablesListChangePollscore,
} from '../../redux/tableReducer'
import Pusher from '../../pusher';
import { API_BASE } from '../../settings'
import api from "../../api";
import useSound from "../../hooks/useSound";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#d6943f',
    color: '#fff',
    border: '1px solid #fff',
    '&.MuiButton-outlined:hover': {
      background: '#ebaa55',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '30px',
    },
  },
}))

interface HeaderProps extends RouteComponentProps<any> {
  setIsMobileNav: () => void
}

const Header: React.FC<HeaderProps> = ({ history, setIsMobileNav }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t, i18n] = useTranslation()
  const { play } = useSound();

  const {
    token,
    data,
    form: { loading: formLoading },
    cashbox: { loading: cashboxLoading, data: cashboxData },
    assigments: { items },
    role: { item: role }
  } = useSelector(authSelector)
  const {
    data: hallData,
    halls: { data: halls },
  } = useSelector(hallSelector)
  const {
    data: tableData,
    tables: { data: tables },
  } = useSelector(tableSelector)
  const { items: currencyList } = useSelector(currencySelector)
  const { component, data: treeInfo } = useSelector(treeInfoSelector)

  React.useEffect(() => {
    !currencyList.length && dispatch(getCurrencyList())
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    !halls.length && dispatch(getHallsList(1, 20))
    // eslint-disable-next-line
  }, [])

  const [pusherClient, setPusherClient] = React.useState<any>(null)

  React.useEffect(() => {
    if (!!data?.pusher && !pusherClient) {
      setPusherClient(
        new Pusher(data.pusher.key, {
          cluster: data.pusher.cluster,
          forceTLS: true,
          authEndpoint: `${API_BASE}auth/pusher`,
          auth: {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              Accept: 'application/json',
            },
          },
        })
      )
    }
    // eslint-disable-next-line
  }, [data?.pusher, pusherClient])

  const [pusherMessage, setPusherMessage] = React.useState<any>(null)

  React.useEffect(() => {
    if (pusherClient && data && token) {
      const channel = pusherClient.subscribe(`private-user-${data.id}`);
      channel.bind("events", (data: any) => {
        data.forEach((event: any) => {
          if (event.action === "logout") {
            if (event.tokens.includes(token)) {
              localStorage.removeItem('token');
              document.location.href = "/";
            }
          }
        });
      });
    }
  }, [pusherClient, data, token])

  React.useEffect(() => {
    let connectedHalls: number[] = []
    for (let key in pusherClient?.channels?.channels) {
      const connectedHall = key.slice(18, key.length)
      connectedHalls = [...connectedHalls, Number(connectedHall)]
    }
    if (!!pusherClient && !!halls.length) {
      halls?.forEach((hall) => {
        if (connectedHalls.indexOf(Number(hall.id)) === -1) {
          const channel = pusherClient.subscribe(`private-hall-info-${hall.id}`)
          channel.bind('set', (data: any) => {
            if (!("id" in data) && ("tables" in data)) {
              for (let t in data.tables) {
                const id = +t;
                if (t in tables) {
                  data.id = tables[id].hall_id;
                  break;
                }
              }
            }
            hall.id === data.id && setPusherMessage({ hall_id: hall.id, data: data });
          })
        }
      })
    }

    return () => {
      if (!!pusherClient) {
        halls?.forEach((hall) => {
          const channel = pusherClient.subscribe(`private-hall-data-${hall.id}`)
          channel.unbind('set')
        })
      }
    }
    // eslint-disable-next-line
  }, [pusherClient, halls.length])

  React.useEffect(() => {
    const hallId: number = pusherMessage?.hall_id;
    if (pusherMessage?.data.tables && hallId) {
      for (let table in pusherMessage?.data.tables) {
        if ("payment" in pusherMessage.data.tables[table]) {
          const type = pusherMessage.data.tables[table].payment.type === 1 ? "payments" : "cards";
          dispatch(api.util.invalidateTags([{ type, id: hallId }]));
          if (type === "payments") {
            play();
          } else if (pusherMessage.data.tables[table].payment.status === 5) {
            play();
          }
          continue;
        }
        const tableObj = tables && tables.find(i => i.id === Number(table));
        if (tableObj) {
          const hallObj = halls && halls.find(i => i.id === tableObj.hall_id);
          if (hallObj) {
            const currency = hallObj.currency_id;
            pusherMessage?.data.tables[table].balance !== undefined &&
              dispatch(tableChangeBalance({ id: Number(table), balance: { [String(currency)]: pusherMessage?.data.tables[table].balance } }));
            tableData?.id === Number(table) &&
              pusherMessage?.data.tables[table].balance !== undefined &&
              dispatch(tableDataChangeBalance({ balance: { [String(currency)]: pusherMessage?.data.tables[table].balance } }));
          }
        }
        if (tables && pusherMessage?.data.tables[table].currentGame !== undefined) {
          dispatch(
            tablesListChangeCurrentGame({
              id: table,
              currentGame: pusherMessage?.data.tables[table].currentGame,
            })
          )
          if (Number(treeInfo?.id) === Number(table) && component === 'table') {
            dispatch(treeInfoChangeCurrentGame(pusherMessage?.data.tables[table].currentGame))
          }
          if (Number(tableData?.id) === Number(table)) {
            dispatch(changeTableCurrentGame(pusherMessage?.data.tables[table].currentGame))
          }
        }

        tables &&
          pusherMessage?.data.tables[table].pollscore !== undefined &&
          dispatch(
            tablesListChangePollscore({
              id: table,
              amount: pusherMessage?.data.tables[table].pollscore,
            })
          )
        tables &&
          pusherMessage?.data.tables[table].highscore !== undefined &&
          dispatch(
            tablesListChangeHighscore({
              id: table,
              amount: pusherMessage?.data.tables[table].highscore,
            })
          )
        tables &&
          pusherMessage?.data.tables[table].online !== undefined &&
          dispatch(
            tablesListChangeOnline({
              id: table,
              online: pusherMessage?.data.tables[table].online,
            })
          )
        tables &&
          pusherMessage?.data.tables[table].is_out !== undefined &&
          dispatch(
            tableChangeIsOut({
              id: +table,
              is_out: pusherMessage?.data.tables[table].is_out,
            })
          )
      }
    }
    if (pusherMessage?.data.balance !== undefined) {
      halls && dispatch(hallChangeBalance({ id: pusherMessage?.hall_id, balance: pusherMessage?.data.balance }));
      hallData?.id === pusherMessage?.hall_id && dispatch(hallDataChangeBalance(pusherMessage?.data.balance));
    }
    // eslint-disable-next-line
  }, [pusherMessage, halls.length, tables.length, play])

  type isConfirmType = {
    open: boolean
  }
  const [isConfirm, setIsConfirm] = React.useState<isConfirmType>({
    open: false,
  })
  const [isCashbox, setIsCashbox] = React.useState<isConfirmType>({
    open: false,
  })

  const getUserCurrencyList = (balance: UserBalance | undefined) => {
    let availableCurrency: string[] = []
    for (let currency in balance) {
      availableCurrency = [...availableCurrency, currency]
    }
    return availableCurrency
  }

  const getDefaultBalance = (balance: UserBalance) => {
    let maxBalance: number
    type BalanceListType = {
      currency: string
      balance: number
    }
    let balanceList: BalanceListType[] = []
    let currencyList: number[] = []
    for (let currency in balance) {
      balanceList = [...balanceList, { currency: currency, balance: balance[currency] }]
      currencyList = [...currencyList, balance[currency]]
    }
    if (balanceList) maxBalance = Math.max.apply(Math, [...currencyList])

    return balanceList.find((i) => i.balance === maxBalance)?.currency
  }

  const [currency, setCurrency] = React.useState<string | undefined>('')
  React.useEffect(() => {
    if (data?.balance) {
      setCurrency(getDefaultBalance(data.balance))
    }
  }, [data])
  const selectedBalance =
    currency && !!data?.balance && data?.balance[currency] ? prettyNum(data.balance[currency]) : '-'

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)

  type AnchorElType = {
    currentTarget: Element | null
  }

  const { showBlock: showPayment, isBlockShown: isPayment, hideBlock: hidePayment } = useShowBlock()

  const [anchor, setAnchor] = React.useState<any>(null)

  const handleClose = () => {
    setAnchor(null)
  }

  const getActiveCashbox = (cashbox: any) => {
    let check: boolean = false
    cashbox &&
      cashbox.forEach((item: any) => {
        if (!!item.cashbox) {
          check = true
        }
      })
    return check
  }

  const cashboxTime =
    cashboxData?.deleted_at &&
    Date.parse(cashboxData.deleted_at) - Date.parse(cashboxData.created_at)

  const convertNum = (number: number) => {
    if (number >= 0 && number < 10) {
      return '0' + number
    } else {
      return number
    }
  }
  const getTimeRemaining = (total: any) => {
    let seconds = convertNum(Math.floor((total / 1000) % 60)),
      minutes = convertNum(Math.floor((total / 1000 / 60) % 60)),
      hours = convertNum(Math.floor(total / (1000 * 60 * 60)) % 24),
      days = convertNum(Math.floor(total / (1000 * 60 * 60 * 24)))

    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    }
  }
  let currentBalance = null;
  if (role === 'cashier' && data?.cashbox) {
    const currentHallId = data?.cashbox.reduce((prev: number | null, cur: any) => prev || (cur.cashbox && cur?.hall_id), null);
    if (currentHallId) {
      halls.every((hall) => {
        if (hall.id === currentHallId) {
          currentBalance = (hall.balance / 100).toFixed(2) + " " + hall.currency_id;
          return false;
        }
        return true;
      });
    }
  }
  const detectedLanguage = localStorage.getItem('i18nextLng')
  return (
    data && (
      <>
        <Wrapper id="header">
          <Grid container>
            {/* logo */}
            <Grid item xs={2} sm={1} className="grid-item">
              <Link to="/">
                <UserBlock>
                  <Logo />
                </UserBlock>
              </Link>
            </Grid>
            {/* locale */}
            <Grid item xs={2} sm={1} className="grid-item">
              <Select
                value={detectedLanguage}
                onChange={(e: any) => {
                  i18n.changeLanguage(e.target.value)
                }}
              >
                <MenuItem value={'ru'}>RU</MenuItem>
                <MenuItem value={'en'}>ENG</MenuItem>
              </Select>
            </Grid>
            {/* balance */}
            <Grid item xs={8} sm={4} className="grid-item">
              <Box display="flex" justifyContent="center" alignItems="flex-end">
                {role === 'cashier' && currentBalance ? <Balance>{currentBalance}</Balance> : <Balance onClick={() => findPermission(items, 'userPutAccount') && showPayment()}>
                  {selectedBalance}
                </Balance>}
                {selectedBalance !== '-' && (
                  <Button
                    style={{ order: 2 }}
                    size="small"
                    onClick={(e: AnchorElType) => setAnchorEl(e.currentTarget)}
                  >
                    {currency}
                  </Button>
                )}
              </Box>
              <Menu
                id="simple-menu"
                keepMounted
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => {
                  setAnchorEl(null)
                }}
              >
                {getUserCurrencyList(data.balance).map((item: string, id: number) => {
                  return (
                    <MenuItem
                      key={id}
                      onClick={() => {
                        setCurrency(item)
                        setAnchorEl(null)
                      }}
                    >
                      {item}
                    </MenuItem>
                  )
                })}
              </Menu>
            </Grid>
            {/* burger btn */}
            <Grid item xs={1} sm={1} className="grid-item">
              <div className="burger">
                <Burger onClick={setIsMobileNav} />
              </div>
            </Grid>
            {/* alert & cashbox*/}
            <Grid item xs={11} sm={5} className="grid-item">
              <Box display="flex" justifyContent="space-between">
                {!!findPermission(items, 'blockYourself') && (
                  <Button
                    className={classes.root}
                    variant="outlined"
                    onClick={() => {
                      alertBlock().then((res) => {
                        if (res.data.code === 200) {
                          localStorage.removeItem('token')
                          dispatch(clearState())
                          history.push('/')
                        }
                      })
                    }}
                  >
                    <Box className="block" display="flex" flexDirection="column">
                      <span style={{ fontSize: '75%' }}>ТРЕВОГА</span>
                      <span style={{ fontSize: '50%', opacity: 0.5 }}>
                        Выйти и заблокировать зал
                      </span>
                    </Box>
                  </Button>
                )}
                {!!data.cashbox.length &&
                  (data && !getActiveCashbox(data.cashbox) ? (
                    <Tooltip title={String(t('ru.common.open_cashbox'))}>
                      <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
                        {cashboxLoading ? (
                          <CircularProgress style={{ color: '#b1b1b1' }} size={17} />
                        ) : (
                          <img src={cashbox} alt="" style={{ opacity: '0.5', width: '25px', height: '25px' }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title={String(t('ru.common.close_cashbox'))}>
                      <IconButton onClick={() => { setIsCashbox({ open: true }) }} >
                        {cashboxLoading ? <CircularProgress style={{ color: '#b1b1b1' }} size={17} /> : <CancelIcon />}
                      </IconButton>
                    </Tooltip>
                  ))}
              </Box>
              {/* user & logout */}
              <Box display="flex" justifyContent="center" alignItems="center">
                <Link to="/me">
                  <User>
                    <div className="avatar">{data.login.slice(0, 1).toUpperCase()}</div>
                    <span className="user">{data.login}</span>
                  </User>
                </Link>
                <Tooltip title={String(t('ru.common.exit_tooltip'))}>
                  <IconButton
                    onClick={() => {
                      setIsConfirm({
                        open: true,
                      })
                    }}
                  >
                    <ExitToAppIcon style={{ color: '#cfcfcf' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>

          <Menu
            id="simple-menu"
            anchorEl={anchor}
            keepMounted
            open={Boolean(anchor)}
            onClose={handleClose}
          >
            {data.cashbox.map((item: any) => {
              return (
                <MenuItem
                  key={item.id}
                  onClick={() => {
                    dispatch(handleOpenCashbox(item.hall_id, item.id))
                    handleClose()
                  }}
                >
                  <Box display="flex" alignItems="center" flexDirection="column">
                    <FormLabel>
                      {halls!.find((i) => i.id === item.hall_id)
                        ? halls.find((i) => i.id === item.hall_id)?.name
                        : ''}
                    </FormLabel>
                    <span style={{ fontSize: '65%' }}>
                      {item.created_at && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="row"
                          component="section"
                        >
                          ({' '}
                          <span style={{ margin: '3px' }}>
                            {convertUTCDateToLocalDate(item.created_at)}
                          </span>
                          )
                        </Box>
                      )}
                    </span>
                  </Box>
                </MenuItem>
              )
            })}
          </Menu>
        </Wrapper>
        {isPayment && (
          <Overlay>
            <PaymentForm
              id={null}
              hidePayment={hidePayment}
              take={(data) => {
                dispatch(handlerTakeAdminBalance(data))
              }}
              putEvent={(data) => {
                dispatch(handlerPutAdminBalance(data))
              }}
              balance={data.balance}
              info={false}
            />
          </Overlay>
        )}
        {isConfirm.open && (
          <Overlay>
            <Confirm
              text={t('ru.common.confirm.logout')}
              loading={formLoading}
              cancel={() => {
                setIsConfirm({ open: false })
              }}
              submit={() => {
                dispatch(handleLogout((path: string) => history.push(path)))
              }}
            />
          </Overlay>
        )}
        {isCashbox.open && (
          <Overlay>
            <Confirm
              text={t('ru.common.confirm.close_cashbox')}
              loading={formLoading}
              cancel={() => {
                setIsCashbox({ open: false })
              }}
              submit={() => {
                dispatch(handleCloseCashbox(setIsCashbox))
              }}
            />
          </Overlay>
        )}
        {cashboxData && (
          <Overlay>
            <CashboxAlert>
              <Box display="flex" flexDirection="column">
                <span>
                  <b>
                    {t('ru.shifts.title')}{' '}
                    {!cashboxData.balance_after
                      ? t('ru.shifts.state.close')
                      : t('ru.shifts.state.open')}{' '}
                  </b>
                  {cashboxData.hall.name}
                </span>
                <span>
                  <b>{t('ru.shifts.fields.balance_start')}:</b>{' '}
                  {prettyNum(cashboxData.balance_before)}
                </span>
                {cashboxData.balance_after && (
                  <span>
                    <b>{t('ru.shifts.fields.balance_end')}:</b>{' '}
                    {prettyNum(cashboxData.balance_after)}
                  </span>
                )}
                {cashboxData.balance_after && (
                  <span>
                    <b>{t('ru.shifts.fields.benefit')}:</b>{' '}
                    {prettyNum(cashboxData.balance_before - cashboxData.balance_after)}
                  </span>
                )}
                <span>
                  <b>{t('ru.shifts.fields.time_start')}:</b>{' '}
                  {cashboxData.created_at && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="row"
                      component="section"
                    >
                      <span style={{ margin: '3px' }}>
                        {convertUTCDateToLocalDate(cashboxData.created_at)}
                      </span>
                    </Box>
                  )}
                </span>
                {cashboxData.deleted_at && (
                  <span>
                    <b>{t('ru.shifts.fields.time_end')}:</b>{' '}
                    {cashboxData.deleted_at && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="row"
                        component="section"
                      >
                        <span style={{ margin: '3px' }}>
                          {convertUTCDateToLocalDate(cashboxData.deleted_at)}
                        </span>
                      </Box>
                    )}
                  </span>
                )}
                {cashboxData.deleted_at && (
                  <span>
                    <b>{t('ru.shifts.fields.total_time')}:</b> <br />
                    {`${getTimeRemaining(cashboxTime).days} д. ${getTimeRemaining(cashboxTime).hours
                      } ч. ${getTimeRemaining(cashboxTime).minutes} м. ${getTimeRemaining(cashboxTime).seconds
                      } с.`}
                  </span>
                )}
                <Button variant="outlined" onClick={() => dispatch(cashboxSucces(null))}>
                  {t('ru.common.close_btn')}
                </Button>
              </Box>
            </CashboxAlert>
          </Overlay>
        )}
      </>
    )
  )
}

export default withRouter(Header)
