import { FormControl, Grid, InputLabel, NativeSelect, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent, useCallback } from 'react';
import { loadHallStatistics } from '../../../services/Halls';
import { useDispatch, useSelector } from 'react-redux';
import { gameSelector } from '../../../redux/gameReducer';
import { tableSelector } from '../../../redux/tableReducer';
import { IHallMath } from '../../../interfaces';
import { setHallStatistics } from '../../../redux/hallReducer';

type StatisticsTabProps = {
    hallId: number,
    math?: IHallMath;
    statistics: any;
};

const StatisticsTab: FunctionComponent<StatisticsTabProps> = ({ hallId, math, statistics }) => {
    const dispatch = useDispatch();
    const [loadingState, setLoadingState] = React.useState<boolean | null>(statistics ? true : null);
    const setStatistics = useCallback((f: any) => dispatch(setHallStatistics(f)), [dispatch]);
    const setType = useCallback((type: number) => type, []);
    if (!hallId || !math) {
        return null;
    }
    const ret = <>
        <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
                <InputLabel>Параметр адаптива:</InputLabel>
                <NativeSelect disabled style={{ width: "100%" }} onChange={(e: ChangeEvent<HTMLSelectElement>) => setType(+e.target.value)} value={math.adaptive_param}>
                    <option value={0} key={0}>Зал</option>
                    <option value={1} key={1}>Стол</option>
                    <option value={2} key={2}>Игры в зале</option>
                    <option value={3} key={3}>Игры в столе</option>
                </NativeSelect>
            </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField disabled value={math.overdraft} label="Овердрафт" />
        </Grid>
    </>;
    if (loadingState === null) {
        setLoadingState(false);
        loadHallStatistics(math.adaptive_param, hallId).then(data => setStatistics(data.data.data));
    }
    if (!statistics || !statistics.statistics.length) {
        return ret;
    }
    return <>
        {ret}
        <StatisticsTable statistics={statistics.statistics} math={math} />
    </>;
};

export default StatisticsTab;

const StatisticsTable: FunctionComponent<any> = ({ statistics, math }) => {
    const { games: { items: games } } = useSelector(gameSelector);
    const { tables: { data: tables } } = useSelector(tableSelector);
    const gameIdToName: any = [];
    games.forEach((game) => gameIdToName[game.id] = game.name);
    const tableIdToName: any = [];
    tables.forEach((table) => tableIdToName[table.id] = table.name);
    return <Table>
        <TableHead>
            <TableRow>
                {statistics[0].table_id && <TableCell>Стол</TableCell>}
                {statistics[0].game_id && <TableCell>Игра</TableCell>}
                <TableCell>Вход</TableCell>
                <TableCell>Выход</TableCell>
                <TableCell>Пэйаут</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {statistics.map((row: any) => <TableRow key={`${row.hall_id}-${row.table_id || 0}-${row.game_id || 0}`}>
                {row.table_id && <TableCell>{tableIdToName[row.table_id] || row.table_id}</TableCell>}
                {row.game_id && <TableCell>{gameIdToName[row.game_id] || row.game_id}</TableCell>}
                <TableCell>{row.debit / 100}</TableCell>
                <TableCell>{row.credit / 100}</TableCell>
                <TableCell>{Math.max(row.debit, math.overdraft * 100) > 0 ? (100 * row.credit / Math.max(row.debit, math.overdraft * 100)).toFixed(2) : "∞"}%</TableCell>
            </TableRow>)}
        </TableBody>
    </Table>;
};