import React from "react"
import { IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close";
import { useStyles, Wrapper } from "./styles";
import { useTranslation } from "react-i18next";
import Table from "./table";

interface ViewCardProps {
  id: number;
  hide: () => void;
  hall_id: number;
}

const ViewCard: React.FC<ViewCardProps> = ({ id, hide, hall_id }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return <Wrapper>
    <IconButton className={classes.closeBtn} onClick={hide}>
      <CloseIcon />
    </IconButton>
    <h3>{t("ru.card.view")}</h3>
    <Table id={id} hide={hide} hall_id={hall_id} />
  </Wrapper>;
}

export default ViewCard
