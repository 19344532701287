import { TableCell, TableCellProps, withStyles } from "@material-ui/core";

const StyledTableCell = withStyles(() => ({
    head: {
        fontSize: '75%',
        lineHeight: 1.6,
        padding: '10px',
    },
    body: {
        fontSize: '75%',
        lineHeight: 1.6,
        padding: '5px',
        textAlign: 'center',
    },
}))(TableCell);

const tmp = (props: TableCellProps) => <StyledTableCell {...props} />;

export default tmp;