import React, { useCallback } from "react";

import { Box, FormHelperText, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { FormWrapper, useStyles } from "./styles";
import Preloader from "../../Common/Preloader";
import { useTranslation } from "react-i18next";
import { useAddCardMutation, useUpdateCardMutation } from "../../../api/card";
import { useForm } from "react-hook-form";

if (global.window) {
  require("react-datepicker/dist/react-datepicker.css");
}

interface CardFormProps {
  hall_id: number;
  hide: () => void;
  card: BankCardRequestUpdate | null;
}

const CardForm: React.FC<CardFormProps> = ({ hide, hall_id, card }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [addCard, { isLoading: isAdding }] = useAddCardMutation();
  const [updateCard, { isLoading: isUpdating }] = useUpdateCardMutation();
  const { register, handleSubmit } = useForm<BankCardRequestUpdate>({ defaultValues: { hall_id, ...card } });
  const handle = useCallback((data: BankCardRequestUpdate) => {
    const f = card ? updateCard : addCard;
    f(data).then(x => "data" in x && hide());
  }, [updateCard, addCard, card, hide]);
  const onSubmit = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleSubmit(handle)();
  }, [handle, handleSubmit]);
  return (isAdding || isUpdating) ? <Preloader absolute={false} /> : <FormWrapper>
    <input type="hidden" {...register("hall_id", { valueAsNumber: true })} />
    <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormHelperText>{t("ru.card.fields.name")}</FormHelperText>
        <TextField {...register("name")} className={classes.textFields} variant="outlined" size="small" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormHelperText>{t("ru.card.fields.number")}</FormHelperText>
        <TextField {...register("number")} className={classes.textFields} variant="outlined" size="small" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormHelperText>{t("ru.card.fields.bank")}</FormHelperText>
        <TextField {...register("bank")} className={classes.textFields} variant="outlined" size="small" />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button fullWidth className={classes.submitBtn} variant="contained" onClick={hide}>
            {t("ru.common.cancel_btn")}
          </Button>
          <Button fullWidth className={classes.submitBtn} disabled={false} variant="contained" onClick={onSubmit}>
            {t(`ru.common.${card ? "save" : "add"}_btn`)}
          </Button>
        </Box>
      </Grid>
    </Grid>
  </FormWrapper>
}

export default CardForm;
