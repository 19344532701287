import React from "react";
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import Preloader from "../../Common/Preloader";
import { useTranslation } from "react-i18next";
import PayoutTableRow from "./payout-table-row";
import StyledTableCell from "./styled-table-sell";
import { useStyles } from "./styles";
import { useFindPayoutsQuery } from "../../../api/payout";

interface CardsListProps {
  hall_id: number;
}
const PayoutsList: React.FC<CardsListProps> = ({ hall_id }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const form: PaymentFormFind = { hall_id, type: 1, statuses: [0, 6] };
  const { data: payments, isLoading } = useFindPayoutsQuery({ data: form });
  return <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ width: "100%" }}>
    {!payments || isLoading ? (
      <Preloader />
    ) : (
      <>
        <TableContainer component={Paper} className={classes.container}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{t("ru.payout.fields.id")}</StyledTableCell>
                <StyledTableCell align="center">{t("ru.payout.fields.status")}</StyledTableCell>
                <StyledTableCell align="center">{t("ru.payout.fields.table")}</StyledTableCell>
                <StyledTableCell align="center">{t("ru.payout.fields.number")}</StyledTableCell>
                <StyledTableCell align="center">{t("ru.payout.fields.name")}</StyledTableCell>
                <StyledTableCell align="center">{t("ru.payout.fields.bank")}</StyledTableCell>
                <StyledTableCell align="center">{t("ru.payout.fields.sum")}</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.list}>
              {payments.map(payment => <PayoutTableRow key={payment.id} hall_id={hall_id} payment={payment} />)}
            </TableBody>
          </Table>
        </TableContainer>
        {payments.length === 0 && <Box style={{ width: "100%", padding: "25px 0" }} display="flex" justifyContent="center">
          <Typography>{t("ru.common.is_absent")}</Typography>
        </Box>}
      </>
    )}
  </Box>;
}

export default PayoutsList
