const ret: any = {};

const useSound = () => {
    if (!("g_sound" in window)) {
        console.log("created!!!");
        (window as any).g_sound = {};
    }
    const name = "/alarm.mp3";
    if (!(name in (window as any).g_sound)) {
        (window as any).g_sound[name] = new Audio(name);
        ret[name] = {
            play: () => {
                console.log((window as any).g_sound[name].paused);
                (window as any).g_sound[name].paused && (window as any).g_sound[name].play();
            },
            stop: () => !(window as any).g_sound[name].paused && (window as any).g_sound[name].stop(),
            pause: () => !(window as any).g_sound[name].paused && (window as any).g_sound[name].pause(),
        }
        console.log("created");
    } else {
        console.log("not created");
    }
    return ret[name];
}

export default useSound;