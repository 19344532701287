import { IHall } from "../../../interfaces";
import CardsList from "../../Cards/List";
import Hotkeys from "../../Hotkeys";
import JackpotsList from "../../Jackpots/List";
import PayoutsList from "../../Payouts/List";
import Binding from "./Binding";
import MathTab from "./Math";
import TablesTab from "./Tables";
import GamePackages from "./game-packages";
import Limits from "./limits";
import Main from "./main";
import StatisticsTab from "./statistics-tab";

interface TabProps {
    tab_id: number;
    hall: IHall;
    showPayment: () => void;
}

const Tab = ({ tab_id, hall, showPayment }: TabProps) => {
    switch (tab_id) {
        case 0:
            return <Main hall={hall} showPayment={showPayment} />;
        case 1:
            return <GamePackages packages={hall.packages} />;
        case 2:
            return <TablesTab hallId={hall.id} />
        case 3:
            return <JackpotsList hall_id={hall.id} />
        case 4:
            return <Binding hall_id={hall.id} />
        case 5:
            return <Limits hall={hall} />
        case 6:
            return <MathTab hallMath={hall.math} />
        case 7:
            return <Hotkeys hallId={hall.id} edit={false} />
        case 8:
            return <StatisticsTab hallId={hall.id} math={hall.math} statistics={hall.statistics} />
        case 9:
            return <CardsList hall_id={hall.id} change={false} />
        case 10:
            return <PayoutsList hall_id={hall.id} />
        default:
            break
    }
    return null;
}

export default Tab;