import { Box, Button, Chip, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IHall } from "../../../../interfaces";
import EnhanceLink from "../../../Common/EnhanceLink";
import { prettyNum } from "../../../../utils/prettyNum";
import { convertUTCDateToLocalDate } from "../../../../utils/date";
import { Status } from "../../../Users/List/styles";
import Item from "../item";
import { useSelector } from "react-redux";
import { userSelector } from "../../../../redux/userReducer";
import { authSelector } from "../../../../redux/authReducer";
import { useCallback, useMemo } from "react";
import { findPermissionOr } from "../../../../utils/find";
import { limitSelector } from "../../../../redux/limitReducer";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { useHistory, useLocation } from "react-router-dom";
import { facesNames } from "../../../../settings/hall";

function getYesOrNo(value: number | boolean) {
    if (value) {
        return "yes";
    }
    return "no";
}

const useStyles = makeStyles(() => ({
    balance: {
        cursor: "pointer",
        color: "white",
        backgroundColor: "#919191",
        padding: "3px",
        fontWeight: "bold",
        width: "max-content"
    },
    chip: {
        margin: ".5vh",
        padding: "0 1vh"
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
        minWidth: "100%"
    },
}));

interface MainProps {
    hall: IHall;
    showPayment: () => void;
};

const Main = ({ hall, showPayment }: MainProps) => {
    const [t] = useTranslation();
    const history = useHistory<{ prevPaths?: string[] }>();
    const location = useLocation<{ prevPaths?: string[] }>();
    const classes = useStyles();
    const { users: { data: users } } = useSelector(userSelector);
    const { role: { item: userRole }, assigments: { items } } = useSelector(authSelector);
    const { items: limitList } = useSelector(limitSelector);
    const isAdmin = useMemo(() => userRole && userRole === "administrator", [userRole]);
    const canUpdateLimit = useMemo(() => findPermissionOr(items, ["hallChildrenUpdateLimit", "updateHallLimit"]), [items]);
    const historyPush = useCallback(() => {
        history.push({
            pathname: location.pathname,
            search: location.search + "&operations_page=1",
            state: {
                prevPaths: location.state && location.state.prevPaths ? location.state.prevPaths : [],
            },
        })
    }, [history, location]);
    const owner = useMemo(() => !!hall.owner_id && users.find((u) => u.id === hall.owner_id), [hall, users]);
    return <>
        <Grid item xs={12} md={6}>
            <Box display="flex">
                <b>{t("ru.halls.fields.user")}:</b>
                {!!hall.owner_id ? <EnhanceLink pathname={`/users`} search={`?id=${hall.owner_id}`}>
                    {owner ? owner.login : `#${hall.owner_id}`}
                </EnhanceLink> : t("ru.common.is_absent")}
            </Box>
        </Grid>
        <Item name={"ru.halls.fields.name"} value={hall.name} />
        <Item name={"ru.halls.fields.company"} value={hall.company.name} />
        <Item name={"ru.halls.fields.city"} value={hall.city.name} />
        <Item name={"ru.halls.fields.adress"} value={hall.address ?? "Отсутствует"} />
        <Grid item xs={12} md={6}>
            <Box onClick={() => showPayment()}>
                <b>{t("ru.halls.fields.balance")}:</b>
                <span className={classes.balance}>
                    {prettyNum(hall?.balance)}
                </span>
            </Box>
        </Grid>
        <Item name={"ru.halls.fields.currency"} value={hall.currency.id} />
        <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t("ru.halls.fields.created_at")}:</b>
                <Chip
                    className={classes.chip}
                    label={
                        hall && <Box style={{ width: "100%" }} display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row" >
                            <span style={{ margin: "3px" }}>
                                {convertUTCDateToLocalDate(hall.created_at)}
                            </span>
                        </Box>
                    }
                />
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t("ru.halls.fields.cashback")}:</b>
                <Chip className={classes.chip} label={<span>{Number(hall?.cashback)}</span>} />
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t("ru.halls.fields.min_cashback_deposit")}:</b>
                <Chip className={classes.chip} label={<span>{Number(hall?.min_cashback_deposit) / 100}</span>} />
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" flexDirection="row">
                <b>{t("ru.halls.fields.status")}:</b>
                <Box display="flex">
                    <Status active={!!hall!.status}>
                        {!!hall!.status ? t("ru.common.status.active") : t("ru.common.status.inactive")}
                    </Status>
                </Box>
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" flexDirection="row">
                <b>{t("Шаблон")}:</b>
                <Box display="flex">
                    <Chip className={classes.chip} label={<span>{t(`ru.halls.selectors.${facesNames[hall.hall_template]}`)}</span>} />
                </Box>
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" flexDirection="row">
                <b>{t("Школа крупье")}:</b>
                <Box display="flex">
                    <Chip className={classes.chip} label={!!hall?.is_school ? "Включено" : "Отключено"} />
                </Box>
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t("ru.halls.fields.default_denom")}:</b>
                <Chip className={classes.chip} label={<span>{Number(hall?.denomination) / 100}</span>} />
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box>
                <b>{t("ru.halls.fields.denominations")}:</b>
                <Box className={classes.chips}>
                    {hall.denominations.map((item) => <Chip className={classes.chip} key={item} label={<span>{Number(item) / 100}</span>} />)}
                </Box>
            </Box>
        </Grid>
        <Item name={"ru.halls.fields.keyboard_type"} value={"ru.halls.selectors.keyboard" + hall.keyboard_type} />
        <Item name={"ru.halls.fields.reel_animation"} value={"ru.halls.selectors.reel_animation" + hall.reel_animation} />
        <Item name={"ru.halls.fields.show_currency"} value={"ru.halls.selectors." + getYesOrNo(hall?.show_currency || 0)} />
        <Item name={"ru.halls.fields.language"} value={"ru.languages." + (hall?.language || "RUS")} />
        <Item name={"ru.halls.fields.jackpot_migration"} value={((hall?.jackpot_migration)?.toString() || "0") + "%"} />
        {isAdmin && <Item name={"ru.halls.fields.poll_inc"} value={((hall?.poll_inc)?.toString() || "10")} />}
        {canUpdateLimit && <Item name={"ru.halls.fields.limit"} value={(hall?.limit_id ? limitList.find((limit) => limit.id === hall.limit_id)?.name : "нет") || ""} />}
        <Item name={"ru.halls.fields.oferta"} value={t("ru.halls.selectors.oferta" + hall?.oferta)} />
        {isAdmin && <Item name={"ru.halls.fields.cashback_reel"} value={hall?.cashback_reel.toString() || "80"} />}
        <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent="center">
                <Button color="default" variant="contained" onClick={historyPush} style={{ margin: "10px" }} >
                    {t("ru.common.history_operations_btn")} <MenuOpenIcon style={{ margin: "0 10px" }} />
                </Button>
            </Box>
        </Grid>
        {!!process.env.URL_ONLINE && <>
            <Grid item xs={12} md={12}>
                <Box>
                    <b>{t("ru.halls.fields.registration_code")}:</b> {hall?.registration_code ? <>{hall?.registration_code} <Button color="default" variant="contained" onClick={() => navigator.clipboard.writeText(`${process.env.URL_ONLINE!}?promocode=${hall?.registration_code}`)}>Копировать</Button></> : t("ru.common.is_absent")}
                </Box>
            </Grid>
            <Item name={"ru.halls.fields.cards"} value={"ru.halls.selectors.cards" + hall.cards} />
        </>}
    </>;
}

export default Main;