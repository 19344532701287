import api from "./index";

export const cardApi = api.injectEndpoints({
    endpoints: (builder) => ({
        findCards: builder.query<BankCard[], WithPagination<BankCardFormFind>>({
            query: (form) => {
                const params: Record<string, string> = {};
                if (form.data) {
                    let k: keyof BankCardFormFind;
                    for (k in form.data) {
                        if (k && form.data[k]) {
                            params[`f[${k}]`] = form.data[k]!.toString();
                        }
                    }
                }
                return { url: `hall/card?${new URLSearchParams(params).toString()}`, method: "get" };
            },
            transformResponse: (response: any) => response.data,
            providesTags: (_result, _error, args) => [{ type: "cards", id: args.data!.hall_id }],
        }),
        addCard: builder.mutation<DefaultResponse<BankCard>, BankCardRequestCreate>({
            query: (form) => ({ url: "hall/card", method: "post", data: form }),
            invalidatesTags: (_result, _error, args) => [{ type: "cards", id: args.hall_id }],
        }),
        updateCard: builder.mutation<DefaultResponse<BankCard>, BankCardRequestUpdate>({
            query: (form) => ({ url: `hall/card/${form.id}`, method: "put", data: form }),
            invalidatesTags: (_result, _error, args) => [{ type: "cards", id: args.hall_id }],
        }),
        deleteCard: builder.mutation<DefaultResponse<boolean>, BankCardRequestId>({
            query: (request) => ({ url: `hall/card/${request.id}`, method: "delete" }),
            invalidatesTags: (_result, _error, args) => [{ type: "cards", id: args.hall_id }],
        }),
        confirmPayment: builder.mutation<DefaultResponse<boolean>, BankCardRequestId>({
            query: (request) => ({ url: `hall/card/confirm/${request.id}`, method: "post" }),
            invalidatesTags: (_result, _error, args) => [{ type: "cards", id: args.hall_id }],
        }),
        cancelPayment: builder.mutation<DefaultResponse<boolean>, BankCardRequestId>({
            query: request => ({ url: `hall/card/cancel/${request.id}`, method: "post" }),
            invalidatesTags: (_result, _error, args) => [{ type: "cards", id: args.hall_id }],
        }),
        viewCard: builder.query<BankCardItem[], WithPagination<BankCardRequestId>>({
            query: (args) => ({ url: `hall/card/${args.data!.id}` }),
            transformResponse: (response: any) => response.data,
            providesTags: (_result, _error, args) => [{ type: "cards-items", id: args.data!.id }],
        }),
    }),
});

export const { useFindCardsQuery, useAddCardMutation, useDeleteCardMutation, useUpdateCardMutation, useCancelPaymentMutation, useConfirmPaymentMutation, useViewCardQuery } = cardApi;