import styled from "styled-components";
import { down } from "styled-breakpoints";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  closeBtn: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  container: {
    maxHeight: 320,
  },
  list: {
    overflow: "auto",
    height: "10vh",
    width: "100%",
    scrollbarWidth: "none",
    "& ::-webkit-scrollbar": {
      width: "0 !important",
    },
  }
}));

export const Wrapper = styled.div`
  position: relative;
  margin: 7vh 0;
  border-radius: 1vh;
  max-width: 800px;
  height: max-content;
  background: #fff;
  padding: 5vh;
  z-index: 999;

  .tabs {
    position: relative;
  }

  .tabs:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #ddd;
    z-index: 1;
  }

  ${down("sm")} {
    width: 100%;
  }
`;