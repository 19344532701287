import styled from "styled-components";
import { down } from "styled-breakpoints";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    closeBtn: {
        position: "absolute",
        top: "0",
        right: "0",
    }
});

export const Wrapper = styled.div`
    position: relative;
    margin: 7vh 0;
    border-radius: 1vh;
    max-width: 600px;
    width: 600px;
    height: max-content;
    background: #fff;
    padding: 5vh;
    z-index: 9999;

    ${down("sm")} {
        width: 100%;
    }
`
