import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles({
  list: {
    overflow: "auto",
    height: "10vh",
    width: "100%",
    scrollbarWidth: "none",
    "& ::-webkit-scrollbar": {
      width: "0 !important",
    },
  },
  container: {
    maxHeight: 320,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "1em",
    padding: "1px",
    borderRadius: "2px",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    gap: "1px",
  },
});

type ActionType = {
  bg: string;
};

export const Action = styled.span`
  width: 3vh;
  height: 3vh;
  max-width: 25px;
  max-height: 25px;
  margin: 0.3vh;
  background: ${(props: ActionType) => props.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
`;
