import { IconButton } from "@material-ui/core";
import React from "react";
import CardForm from "../Form";
import CloseIcon from "@material-ui/icons/Close";
import { Wrapper, useStyles } from "./styles";
import { useTranslation } from "react-i18next";

interface ChangeCardProps {
  card: BankCardRequestUpdate;
  hide: () => void;
}

const ChangeCard: React.FC<ChangeCardProps> = ({ hide, card }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return <Wrapper>
    <IconButton className={classes.closeBtn} onClick={hide}>
      <CloseIcon />
    </IconButton>
    <h3>{t("ru.card.change_form")}</h3>
    <CardForm hide={hide} hall_id={card.hall_id} card={card} />
  </Wrapper>;
}

export default ChangeCard;
