import { IconButton } from "@material-ui/core";
import React from "react";
import CardForm from "../Form";
import CloseIcon from "@material-ui/icons/Close";
import { Wrapper, useStyles } from "./styles";
import { useTranslation } from "react-i18next";

interface AddCardProps {
  hall_id: number;
  hide: () => void;
}

const AddCard: React.FC<AddCardProps> = ({ hide, hall_id }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return <Wrapper>
    <IconButton className={classes.closeBtn} onClick={hide}>
      <CloseIcon />
    </IconButton>
    <h3>{t("ru.card.add_form")}</h3>
    <CardForm hide={hide} hall_id={hall_id} card={null} />
  </Wrapper>;
}

export default AddCard;
