import React, { useMemo } from 'react';
import { useCancelPaymentMutation, useConfirmPaymentMutation } from '../../../api/card';
import { useStyles } from './styles';

interface CardTableItemCellProps {
    item: BankCardItem;
    hall_id: number;
}

const CardTableItemCell: React.FC<CardTableItemCellProps> = ({ item, hall_id }) => {
    const classes = useStyles();
    const [confirm, { isLoading: isConfirm }] = useConfirmPaymentMutation();
    const [cancel, { isLoading: isCancel }] = useCancelPaymentMutation();
    const isLoading = useMemo(() => isConfirm || isCancel, [isCancel, isConfirm]);
    return <div className={classes.row} style={{ backgroundColor: item.status === 5 ? "lightgreen" : "inherit" }}>
        <div>{(item.sum / 100).toFixed()} на стол № {item.gambling_table_id}</div>
        <div className={classes.buttons}>
            <button disabled={isLoading} onClick={() => confirm({ id: item.id, hall_id })}>Подтвердить</button>
            <button disabled={isLoading} onClick={() => cancel({ id: item.id, hall_id })}>Отклонить</button>
        </div>
    </div>;
}

export default CardTableItemCell;
