import React, { useMemo } from "react";

import List from "@material-ui/core/List";
import {
  Box,
  Grid,
  makeStyles,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { Wrapper, TabItem } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { hallSelector, getHall, hallSuccess, handlerChangeHall } from "../../../redux/hallReducer";
import Preloader from "../../Common/Preloader";
import editPng from "../../../assets/images/edit.png";
import visionPng from "../../../assets/images/vision.png";
import HallForm from "../Form";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authSelector } from "../../../redux/authReducer";
import { isConfirmType } from "../../../interfaces";
import GoBackBtn from "../../Common/GoBackBtn"
import { findPermission, findPermissionOr } from "../../../utils/find";
import { gameSelector, getAllGames } from "../../../redux/gameReducer";
import Tab from "./tab";

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: "absolute",
    top: "0",
    right: "0"
  },
  backBtn: {
    position: "absolute",
    top: "0",
    left: "0"
  },
  tables: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  tablesCount: {
    width: "75px"
  }
}));

interface HallProps {
  hideHall: () => void
  hallId: null | number
  isChangeMode: boolean
  setIsChangeMode: (isChangeMode: boolean) => void
  setIsCancel: (isCancel: isConfirmType) => void
  showPayment: () => void
  showDistribution: () => void
}

const Hall: React.FC<HallProps> = ({
  hideHall,
  hallId,
  showPayment,
  isChangeMode,
  setIsChangeMode,
}) => {
  const dispatch = useDispatch();
  const classes: any = useStyles();
  const [t] = useTranslation();
  const { data: hall, loading } = useSelector(hallSelector);
  const {
    role: { item: userRole },
    assigments: { items },
  } = useSelector(authSelector);

  const {
    games: { items: games },
  } = useSelector(gameSelector);

  React.useEffect(() => {
    !games.length && dispatch(getAllGames(1, 50))
    // eslint-disable-next-line
  }, [])

  const [isChange, setIsChange] = React.useState(isChangeMode)
  React.useEffect(() => {
    return () => {
      setIsChangeMode(false)
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    hallId && dispatch(getHall(hallId))
    return () => {
      dispatch(hallSuccess(null))
    }
  }, [dispatch, hallId]);

  const [tab, setTab] = React.useState(0)
  const history = useHistory<{ prevPaths?: string[] }>();
  const location = useLocation<{ prevPaths?: string[] }>();
  const isBackButton = useMemo(() => !!location.state && !!location.state.prevPaths && location.state.prevPaths.length > 0, [location]);
  const canUpdateHall = useMemo(() => findPermission(items, "viewHall") && findPermission(items, "updateHall"), [items]);
  const canShowMath = useMemo(() => findPermissionOr(items, ["showHallMath", "showChildrenHallMath"]), [items]);
  const updateOrViewButton = useMemo(() => !isChange ? editPng : visionPng, [isChange]);
  const updateOrViewButtonTitle = useMemo(() => t(`ru.common.${isChange ? "view_tooltip" : "change_btn"}`), [isChange, t]);
  const isNotCashier = useMemo(() => userRole && userRole !== "cashier", [userRole]);
  return <Wrapper>
    {!hall || loading ? <Preloader /> : <>
      {isBackButton && <GoBackBtn />}
      <Box className={classes.closeBtn}>
        {canUpdateHall && <Tooltip title={updateOrViewButtonTitle} placement="top" >
          <IconButton onClick={() => setIsChange(!isChange)}>
            <Box display="flex" justifyContent="center" alignItems="center" style={{ background: "#b6b6b6b6", width: "30px", minHeight: "30px", borderRadius: "50%", padding: "6px" }} >
              <img style={{ width: "20px", minHeight: "auto" }} src={updateOrViewButton} alt="icon" />
            </Box>
          </IconButton>
        </Tooltip>}
        <IconButton onClick={() => history.push("halls")} >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center">
        <AccountCircleIcon fontSize="large" />
        <h2>{hall.name}</h2>
      </Box>
      {!isChange ? <>
        <Box className="tabs" display="flex" justifyContent="flex-start" style={{ overflowX: "auto" }} >
          <TabItem active={tab === 0} onClick={() => setTab(0)} >
            {t("ru.halls.tabs.info")}
          </TabItem>
          <TabItem active={tab === 1} onClick={() => setTab(1)} >
            {t("ru.halls.tabs.packets")}
          </TabItem>
          <TabItem active={tab === 2} onClick={() => setTab(2)} >
            {t("ru.halls.tabs.tables")}
          </TabItem>
          {isNotCashier && <TabItem active={tab === 3} onClick={() => setTab(3)} >
            {t("ru.halls.tabs.jackpots")}
          </TabItem>}
          {isNotCashier && <TabItem active={tab === 4} onClick={() => setTab(4)} >
            {t("ru.halls.tabs.binding")}
          </TabItem>}
          {canShowMath && <TabItem active={tab === 6} onClick={() => setTab(6)} >
            {t("Математика")}
          </TabItem>}
          {canShowMath && <TabItem active={tab === 8} onClick={() => setTab(8)} >
            {t("Статистика")}
          </TabItem>}
          <TabItem active={tab === 7} onClick={() => setTab(7)} >
            {t("ru.halls.tabs.hotkeys")}
          </TabItem>
          {(hall.cards & 1) === 1 && <TabItem active={tab === 9} onClick={() => setTab(9)} >
            {t("ru.halls.tabs.cards")}
          </TabItem>}
          {(hall.cards & 2) === 2 && <TabItem active={tab === 10} onClick={() => setTab(10)} >
            {t("ru.halls.tabs.payouts")}
          </TabItem>}
        </Box>
        <List>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Tab tab_id={tab} hall={hall} showPayment={showPayment} />
            </Grid>
          </div>
        </List>
      </> :
        <HallForm
          onSubmit={(data, packages, math, hideForm, hallId, info) =>
            dispatch(handlerChangeHall(data, packages, math, hideForm, hallId, info))
          }
          cancelBtnEvent={hideHall}
          cancelBtnText={t("ru.common.back_btn")}
          submitBtnText={t("ru.common.submit_btn")}
          id={hall.id}
          name={hall.name}
          city_id={hall.city_id}
          limit_id={hall.limit_id}
          currency_id={hall.currency_id}
          company_id={hall.company_id}
          owner_id={hall.owner_id}
          cashback={hall.cashback}
          out_percent={hall.out_percent}
          poll_inc={hall.poll_inc}
          address={hall.address ?? ""}
          status={hall.status}
          packets={hall.packages}
          denomination={hall.denomination}
          denominations={hall.denominations}
          keyboard_type={hall.keyboard_type}
          show_currency={hall.show_currency}
          language={hall.language}
          jackpot_migration={hall.jackpot_migration}
          reel_animation={hall.reel_animation}
          max_win_from_deposit={hall.max_win_from_deposit}
          max_win_per_spin={hall.max_win_per_spin}
          win_per_spin_percent_bet={hall.win_per_spin_percent_bet}
          win_from_deposit_for_lose={hall.win_from_deposit_for_lose}
          max_deposit_for_lose={hall.max_deposit_for_lose}
          min_cashback_deposit={hall.min_cashback_deposit}
          math_type={hall.math_type}
          hall_template={hall.hall_template}
          is_social_poll={hall.is_social_poll}
          show_logo={hall.show_logo}
          text={hall.text}
          is_school={hall.is_school}
          oferta={hall.oferta}
          cashback_reel={hall.cashback_reel}
          hideForm={() => setIsChange(false)}
          tab={tab}
          setTab={setTab}
          math={hall?.math}
          change
          registration_code={hall.registration_code}
          cards={hall.cards}
        />}
    </>}
  </Wrapper>
}

export default Hall
