import { makeStyles, withStyles, TableCell } from '@material-ui/core'
import styled from 'styled-components'

export const IssuesWrapper = styled.div`
  width: 100%;
  .tabs {
    position: relative;
  }

  .tabs:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #ddd;
    z-index: 1;
  }
`
export const Wrapper = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 0.05);
  padding: 3vh;
  margin-top: 3vh;

  h3 {
    font-size: 95%;
    margin: 5px 0;
  }

  b {
    font-size: 90%;
    line-height: 25x;
  }

  span {
    font-size: 84%;
  }
`
type TabItemType = {
  active: boolean
}
export const TabItem = styled.span`
  background: #fff;
  color: ${(props: TabItemType) => (props.active ? '#666' : '#ddd')};
  border: ${(props: TabItemType) => (props.active ? '1px solid #ddd' : 'none')};
  border-bottom: ${(props: TabItemType) => (props.active ? 'none' : '1px solid #ddd')};
  cursor: pointer;
  padding: 10px;
  z-index: 10;
`
export const Form = styled.form`
  position: relative;
  margin: 7vh 0;
  border-radius: 1vh;
  max-width: 600px;
  width: 600px;
  height: max-content;
  background: #fff;
  padding: 5vh;
  z-index: 9999;
`

export const useStyles = makeStyles({
  item: {
    width: '100%',
    padding: '3vh',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
    fontSize: '90%',
  },
  span: {
    color: '#555',
    minHeight: '100%',
    background: '#eee',
    padding: '0 3%',
  },
  reload: {
    color: 'white',
    background: '#a9c477',
    borderRadius: 0,
    width: 'max-content',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f3f3f3',
    },
    cursor: 'pointer',
  },
})

export const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: '76%',
  },
}))(TableCell)

type MessageType = {
  bg: string
}
export const Message = styled.div`
  border: 2px solid #dedede;
  background-color: ${(props: MessageType) => props.bg ? props.bg : ''};
  border-radius: 5px;
  padding: 10px;
  margin: 7px 0;
  display: flex;
  flex-direction: column;

  .date {
    font-size: 70%;
  }
`
export const Messages = styled.div`
  min-height: 300px;
  height: 360px;
  overflow: scroll;
  ::-webkit-scrollbar {
      width: 0 !important;
  }
`