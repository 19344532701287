import React from "react";
import { Box, IconButton, TableRow } from "@material-ui/core";
import visionPng from "../../../assets/images/vision.png";
import editPng from "../../../assets/images/edit.png";
import trashPng from "../../../assets/images/trash.png";
import { Action } from "./styles";
import CardTableItemCell from "./card-table-item-cell";
import StyledTableCell from "./styled-table-sell";

interface TableRowProps {
    card: BankCard;
    change: boolean;
    setEditCard: (card: BankCardRequestUpdate) => void;
    setDeleteCardId: (id: number) => void;
    setViewCardId: (id: number) => void;
}

const CardTableRow: React.FC<TableRowProps> = ({ card, change, setDeleteCardId, setEditCard, setViewCardId }) => {
    return <TableRow>
        <StyledTableCell>{card.id}</StyledTableCell>
        <StyledTableCell>{card.name}</StyledTableCell>
        <StyledTableCell>{card.number}</StyledTableCell>
        <StyledTableCell>{card.bank}</StyledTableCell>
        <StyledTableCell>{card.items.map(item => <CardTableItemCell key={item.id} item={item} hall_id={card.hall_id} />)}</StyledTableCell>
        <StyledTableCell>
            <Box display="flex" justifyContent="flex-end">
                {<Action bg={"#7467ec"} onClick={() => setViewCardId(card.id)}>
                    <IconButton>
                        <img src={visionPng} alt="icon" style={{ width: "30%" }} />
                    </IconButton>
                </Action>}
                {change && <Action bg={"#919191"} onClick={() => setEditCard({ ...card })}>
                    <IconButton>
                        <img src={editPng} alt="icon" style={{ width: "30%" }} />
                    </IconButton>
                </Action>}
                {change && <Action bg={"#919191"} onClick={() => setDeleteCardId(card.id)}>
                    <IconButton>
                        <img src={trashPng} alt="icon" style={{ width: "35%" }} />
                    </IconButton>
                </Action>}
            </Box>
        </StyledTableCell>
    </TableRow>;
}

export default CardTableRow;
