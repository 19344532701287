import React, { useMemo } from "react";
import { Box, TableRow } from "@material-ui/core";
import StyledTableCell from "./styled-table-sell";
import { useTranslation } from "react-i18next";
import { useCancelPayoutMutation, useConfirmPayoutMutation, useProcessPayoutMutation } from "../../../api/payout";

interface TableRowProps {
    payment: Payment;
    hall_id: number;
}

const PayoutTableRow: React.FC<TableRowProps> = ({ payment, hall_id }) => {
    const [t] = useTranslation();
    const [cancel, { isLoading: isCanceling }] = useCancelPayoutMutation();
    const [confirm, { isLoading: isConfirming }] = useConfirmPayoutMutation();
    const [process, { isLoading: isProcessing }] = useProcessPayoutMutation();
    const isLoading = useMemo(() => isCanceling && isConfirming && isProcessing, [isCanceling, isConfirming, isProcessing]);
    return <TableRow>
        <StyledTableCell>{payment.id}</StyledTableCell>
        <StyledTableCell>{t(`ru.payout.selectors.status${payment.status}`)}</StyledTableCell>
        <StyledTableCell>{payment.gambling_table_id}</StyledTableCell>
        <StyledTableCell>{payment.data?.holder_account}</StyledTableCell>
        <StyledTableCell>{payment.data?.holder_name}</StyledTableCell>
        <StyledTableCell>{payment.data?.bank}</StyledTableCell>
        <StyledTableCell>{(payment.sum / 100).toFixed(2)}</StyledTableCell>
        <StyledTableCell>
            <Box display="flex" justifyContent="flex-end">
                <button disabled={isLoading} onClick={() => cancel({ hall_id, id: payment.id })}>Отмена</button>
                <button disabled={isLoading} onClick={() => confirm({ hall_id, id: payment.id })}>Подтвердить</button>
                {payment.status === 0 && <button disabled={isLoading} onClick={() => process({ hall_id, id: payment.id })}>В обработку</button>}
            </Box>
        </StyledTableCell>
    </TableRow>;
}

export default PayoutTableRow;
