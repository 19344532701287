import styled from "styled-components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    textFields: {
        width: "100%",
    },
    root: {
        "& .MuiPopover-root": {
            margin: "1vh",
            width: 200,
        },
    },
    submitBtn: {
        margin: "1vh"
    }
});

export const FormWrapper = styled.form`
    position: relative;
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
