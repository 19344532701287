import React from "react";
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useViewCardQuery } from "../../../api/card";
import Preloader from "../../Common/Preloader";
import { StyledTableCell } from "../../Issues/styles";
import { convertUTCDateToLocalDate } from "../../../utils/date";

interface ViewCardProps {
    id: number;
    hide: () => void;
    hall_id: number;
}

const ItemsTable: React.FC<ViewCardProps> = ({ id, hall_id }) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { data, isLoading } = useViewCardQuery({ data: { id, hall_id } });
    return <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ width: "100%" }}>
        {isLoading || !data ? <Preloader /> : <TableContainer component={Paper} className={classes.container}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center">{t("ru.card.items.id")}</StyledTableCell>
                        <StyledTableCell align="center">{t("ru.card.items.sum")}</StyledTableCell>
                        <StyledTableCell align="center">{t("ru.card.items.created_at")}</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.list}>
                    {data.map(item => <TableRow key={item.id}>
                        <StyledTableCell>{item.id}</StyledTableCell>
                        <StyledTableCell>{(item.sum / 100).toFixed(2)}</StyledTableCell>
                        <StyledTableCell>{convertUTCDateToLocalDate(item.created_at)}</StyledTableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>}
    </Box>;
}

export default ItemsTable
